// Configure requireJS
window.require = {
	baseUrl : '/js',
	baseBlocksUrl : 'blocks',
	paths : {
		jquery : 'vendor/jquery/jquery-3.5.1',
		'jquery.validate' : 'vendor/jquery/jquery.validate',
		'jquery.validate.wrapper' : 'wrappers/jquery.validate.wrapper',
		'jquery.validate.unobtrusive' : 'vendor/jquery/jquery.validate.unobtrusive',
		'jquery.validate.unobtrusive.wrapper' : 'wrappers/jquery.validate.unobtrusive.wrapper',
		'jquery.validate.unobtrusive-ajax' : 'vendor/jquery/jquery.validate.unobtrusive-ajax',
		Modernizr : 'vendor/core/modernizr',
		b : 'block',
	},
	// Contains the attributes that a module or modules in a path should have which is used in onNodeCreated
	moduleAttributes : {
		'blocks/' : { crossorigin : 'anonymous' },
		'enhancements/' : { crossorigin : 'anonymous' },
		'stand-alone/' : { crossorigin : 'anonymous' },
		'utilities/' : { crossorigin : 'anonymous' },
		'validation/' : { crossorigin : 'anonymous' },
		'vendor/' : { crossorigin : 'anonymous' },
		'wrappers/' : { crossorigin : 'anonymous' },
		main : { crossorigin : 'anonymous' },
		b : { crossorigin : 'anonymous' },
		locale : { crossorigin : 'anonymous' },
		pubsub : { crossorigin : 'anonymous' },
		'utility-non-jq' : { crossorigin : 'anonymous' },
		utility : { crossorigin : 'anonymous' },
		jquery : { crossorigin : 'anonymous' },
		Modernizr : { crossorigin : 'anonymous' },
	},
	// Callback after a node is created using require
	onNodeCreated : function callback( node, config, module ) {
		'use strict';
		// Set the keys of moduleAttributes into an array
		var moduleAttributesKeys = Object.keys( config.moduleAttributes ),
			moduleAttributesKey,
			moduleAttributes,
			// Get the index of the module in the moduleAttributesKeys array
			indexCheck = moduleAttributesKeys.indexOf( module ),
			// If module is found then set the start index to it else start at 0
			i = ( indexCheck === -1 ) ? 0 : indexCheck,
			n,
			// Bools used for checking if module is in directory e.g vendor/
			startsBool,
			endsBool;

		// Loop through the keys
		for ( i; i < moduleAttributesKeys.length; i += 1 ) {
			// Checks if module name starts with with the key name
			startsBool = module.indexOf( moduleAttributesKeys[ i ] ) === 0;
			// Checks to see if key name ends with '/' to ensure it's a directory
			endsBool = moduleAttributesKeys[ i ].lastIndexOf( '/' ) === ( moduleAttributesKeys[ i ].length - '/'.length );

			// If the key matches the module enter if statement or if module starts with the key and the key ends with a '/' then enter if statement (for module in directory)
			if ( ( module === moduleAttributesKeys[ i ] ) || ( startsBool && endsBool ) ) {
				moduleAttributesKey = moduleAttributesKeys[ i ];
				// Make array with the attribute keys of the module
				moduleAttributes = Object.keys( config.moduleAttributes[ moduleAttributesKey ] );
				// Loop through the attributes and set the attribute with the value
				for ( n = 0; n < moduleAttributes.length; n += 1 ) {
					node.setAttribute( moduleAttributes[ n ], config.moduleAttributes[ moduleAttributesKey ][ moduleAttributes[ n ] ] );
				}
				// Break out of for loop as all attributes have already been set
				break;
			}
		}
	},
	packages : [
		{
			name : 'moment',
			location : 'vendor',
			main : 'moment',
		},
	],
	shim : {
		'vendor/jquery/mCustomScrollbar' : [ 'jquery' ],
		'vendor/prettify/prettify' : [ 'jquery' ],
		'vendor/pikaday' : [ 'moment' ],
		'jquery.validate.unobtrusive-ajax' : [ 'jquery', 'jquery.validate', 'jquery.validate.unobtrusive' ],
		Modernizr : { exports : 'Modernizr' },
	},
	map : {
		'*' : {
			'jquery.validate' : 'jquery.validate.wrapper',
			'jquery.validate.unobtrusive' : 'jquery.validate.unobtrusive.wrapper',
		},
		'jquery.validate.wrapper' : { 'jquery.validate' : 'jquery.validate' },
		'jquery.validate.unobtrusive.wrapper' : { 'jquery.validate.unobtrusive' : 'jquery.validate.unobtrusive' },
	},
	callback : function() {
		'use strict';
		var event;
		if ( typeof Event === 'function' ) {
			event = new Event( 'requireready' );
		} else {
			event = document.createEvent( 'Event' );
			event.initEvent( 'requireready', true, true );
		}
		document.dispatchEvent( event );
	},
};

// Extend require config
// eslint-disable-next-line complexity
( function fn() {
	'use strict';
	var require,
		requireExtend,
		objs,
		arrs,
		blocked,
		origCallback,
		initScript,
		currentUrl,
		appUrl,
		head,
		script;

	// Check browser has minimum level of feature support
	if ( 'querySelector' in document && 'sessionStorage' in window && 'addEventListener' in window ) {
		// Object.assign polyfill
		if ( typeof Object.assign !== 'function' ) {
			// Must be writable: true, enumerable: false, configurable: true
			Object.defineProperty( Object, 'assign', {
				value : function assign( target, varArgs ) { // eslint-disable-line no-unused-vars
					var to,
						index,
						nextSource;

					// .length of function is 2
					if ( target === null ) { // TypeError if undefined or null
						throw new TypeError( 'Cannot convert undefined or null to object' );
					}

					to = Object( target );

					for ( index = 1; index < arguments.length; index++ ) {
						nextSource = arguments[ index ];

						if ( nextSource !== null ) { // Skip over if undefined or null
							Object.keys( nextSource ).forEach( function loop( nextKey ) { // eslint-disable-line no-loop-func
								// Avoid bugs when hasOwnProperty is shadowed
								if ( Object.prototype.hasOwnProperty.call( nextSource, nextKey ) ) {
									to[ nextKey ] = nextSource[ nextKey ];
								}
							});
						}
					}
					return to;
				},
				writable : true,
				configurable : true,
			});
		}

		require = window.require;
		requireExtend = window.requireExtend;
		objs = [ 'paths', 'bundles', 'shim', 'map', 'config', 'moduleAttributes' ];
		arrs = [ 'packages', 'deps' ];
		blocked = [ 'baseUrl', 'skipDataMain', 'baseBlocksUrl' ];

		if ( requireExtend && typeof requireExtend === 'object' ) {
			Object.keys( requireExtend ).forEach( function loop( prop ) {
				var x, y;
				if ( blocked.indexOf( prop ) > -1 ) {
					throw new Error( 'You are not permitted to override `' + prop + '` in your config' );
				}

				if ( objs.indexOf( prop ) > -1 ) {
					require[ prop ] = Object.assign({}, requireExtend[ prop ], require[ prop ] );
					return;
				}

				if ( arrs.indexOf( prop ) > -1 ) {
					x = require[ prop ] || [];
					y = requireExtend[ prop ] || [];
					require[ prop ] = [].concat( x, y );
					return;
				}

				if ( prop === 'callback' && requireExtend.callback === 'function' ) {
					// Callback already exists on config, wrap and call both
					if ( require.callback ) {
						origCallback = require.callback;
						require.callback = function callback() {
							origCallback.apply( this, arguments );
							requireExtend.callback.apply( this, arguments );
						};
					} else {
						require.callback = requireExtend.callback;
					}
					return;
				}

				require[ prop ] = requireExtend[ prop ];
			});
		}

		try {
			if ( sessionStorage.getItem( 'fonts-loaded' ) === 'success' ) {
				window.document.documentElement.className += ' fonts-loaded';
			}
		} catch ( e ) {
			// Do nothing
		}

		// Set base URL based on where init.js is being loaded from
		initScript = document.getElementById( 'js-init' );
		if ( initScript !== null ) {
			currentUrl = initScript.src;
			require.baseUrl = currentUrl.replace( '/init.js', '' );

			appUrl = initScript.getAttribute( 'data-app' );
			if ( appUrl !== null ) {
				require.paths.app = appUrl;
			}
		}

		// Insert requireJS in page
		head = document.getElementsByTagName( 'head' )[ 0 ];
		script = document.createElement( 'script' );
		script.src = require.baseUrl + '/vendor/core/require.js';
		script.setAttribute( 'data-main', 'main' );
		script.setAttribute( 'crossorigin', 'anonymous' );
		head.appendChild( script );
	}
})();
